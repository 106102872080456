<template>
	<div>
		<v-textarea
			:id="id"
			ref="input-text-area"
			:label="label"
			:loading="loading"
			:disabled="disabled"
			:hide-details="hideDetails"
			outlined
			:rows="rows"
			:rules="rules"
			class="pt-0"
			:class="{ 'mt-3': !dense }"
			v-model="textareainput"
			v-on:keypress="(e) => $emit('keypress', e)"
			v-on:paste="(e) => onPaste(e)"
			:placeholder="placeholder"
		></v-textarea>
		<div class="text-right pt-1">{{ textareainput ? textareainput.length : 0 }}/{{this.maxLength}}</div>
	</div>
</template>
<script>
export default {
	name: "textarea-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		rows: {
			type: Number,
			default: 3,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		maxLength: {
			type: Number,
			default: 200,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textareainput: null,
		};
	},
	methods: {
		onPaste(e) {
			if (e && e.clipboardData && this.maxLength) {
				let pasteValue = e.clipboardData.getData("Text");
				let preVal = this.textareainput;
				let finalval = String(preVal) + String(pasteValue);
				if (Number(finalval.length) > Number(this.maxLength)) {
					let trimValue = finalval.substring(0, this.maxLength);
					this.textareainput = trimValue;
					this.$emit("input", this.textareainput);
					e.preventDefault();
				}
			}
		},
	},
	watch: {
		value() {
			this.textareainput = this.value;
		},
		textareainput() {
			this.$emit("input", this.textareainput);
		},
	},
	mounted() {
		this.textareainput = this.value;
	},
};
</script>
